<template>
  <v-card>
    <v-card-title>
      图片预览
    </v-card-title>

    <v-card-text
      class="d-flex justify-center"
    >
      <img
        :src="imgSrc"
        alt="暂无图片"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { useRouter } from '@core/utils'
import { AliOSS } from '@core/utils/useAliOSS'
import { ref } from '@vue/composition-api'
import { modalFail } from '@core/utils/prompt'

export default {
  name: 'ImgPreview',
  setup() {
    const bucket = 'hzxw-video-mcn'
    const { route } = useRouter()
    const imgSrc = ref('')
    const aliOss = new AliOSS(bucket)
    if (!route.value.query.path) {
      modalFail('未获取到图片！')
    } else {
      aliOss.fetchOSS().then(() => {
        imgSrc.value = aliOss.fetchDownloadLink(route.value.query.path)
      })
    }

    return {
      imgSrc,
    }
  },
}
</script>

<style scoped>

</style>
